<template>  
    <v-row justify="center">
        <div class="banner text-center " :class="clipped ? 'cliped-rb-path':''" :style="bannerStyle">
            <div :style="imgStyle"></div>
            <div v-if="title" class="primary text-center banner-title2">
                    <strong class="black--text text-uppercase">{{ title }}</strong>
            </div>
        </div>
    </v-row>
</template>

<script>

export default {
    props: {
        title: String,
        src: String,
        clipped: {
            type: Boolean,
            default: true,
        }, 
        height: {
            type: String,
            default: '75px'
        },
        width: {
            type: String,
        }
    },
    computed: {
        imgStyle: function () {
            return {
                /*'background-image': "url('"+this.src+"')",
                'background-repeat': 'no-repeat',
                'background-position': 'center center', 
                'background-size': 'cover',*/
                'border-radius': '2px',
                height: '100%',
            }
        },
        bannerStyle: function () {
            return {
                'height': this.height || '75px',
                'max-height': this.height || '75px',
                'width': this.width || '100%',
                'max-width': this.width || '100%',
                //'min-width': this.width || '100%',

            }
        }
    }
}
</script>