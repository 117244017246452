<template>
    <div v-if="item" class="news">
        <div style="padding-bottom: 15px; padding-top: 0;"></div>
        <BannerTitle v-if="item.banner || (item.text && item.text.title)" 
            :src="item.banner ? item.banner.src : null" 
            :title="item ? item.text.title : null" 
            width="1350px"/>
        <br>
        <v-container fluid>
            <v-row 
            justify="center">
                <div v-if="item.text" style="width: 1250px; max-width: 1250px" v-html="item.text.txt" class="news-body text-justify">

                    </div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import BannerTitle from './BannerTitle';

export default {
    props: ['item'],
    data: () => ({}),
    
    components: {
        BannerTitle,
    },
    
    
}
</script>